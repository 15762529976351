import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InquiriesService {
  constructor(private http: HttpClient) {}

  private PilgrimDetails = new BehaviorSubject<any>('');
  currentPilgrimDetails = this.PilgrimDetails.asObservable();

  setPilgrimDetails(PilgrimDetails: any) {
    this.PilgrimDetails.next(PilgrimDetails);
  }

  getPilgrimDetails(data: any): Observable<any> {
    return this.http.post<any>(environment.citizensApi + '/pilgrims', data);
  }
}
