import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TravelsService {
  constructor(private http: HttpClient) {}

  getListTravels(): Observable<any> {
    return this.http.get<any>(environment.citizensApi + '/packages/my-travels');
  }
  getMyTravels(): Observable<any> {
    return this.http.get<any>(environment.citizensApi + '/covid');
  }
  getTravelsDetails(id: any): Observable<any> {
    return this.http.get<any>(
      environment.citizensApi + '/packages/my-travels/' + id
    );
  }

  ratePilgrim(obj: any): Observable<any> {
    return this.http.post(environment.citizensApi + '/pilgrims/rate', obj);
  }
}
